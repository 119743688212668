import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import Landing from '../templates/Landing';

const host = process.env.GATSBY_HOST;

const iot = {
  header: {
    title: 'iotHeaderTitle',
    subTitle: 'iotHeaderSubTitle',
    image: 'iot.png',
    button1: {
      text: 'registration',
      subText: 'registrationSubText',
      link: `${host}/auth2/single_account?redirect_uri=${host}/&enter=signup`,
      event: 'IOT_get_started',
      id: 'sign_up_iot'
      
    },
    button2: {
      text: 'orderDemo',
      link: '/form/live-demo',
      event: 'IOT_request_demo',
      id: 'request_demo_iot'
    }
  },
  content: [
    {
      title: 'iotContentIronParkTitle',
      subTitle: 'iotContentIronParkSubTitle',
      image: 'iot-1.svg',
    },
    {
      title: 'iotContentEventTitle',
      subTitle: 'iotContentEventSubTitle',
      image: 'iot-2.svg',
    },
    {
      title: 'iotContentEscalTitle',
      subTitle: 'iotContentEscalSubTitle',
      image: 'iot-3.svg',
    },
    {
      title: 'iotContentOnlineTitle',
      subTitle: 'iotContentOnlineSubTitle',
      image: 'iot-4.svg',
    },
    {
      title: 'iotContentPerformanceTitle',
      subTitle: 'iotContentPerformanceSubTitle',
      image: 'iot-5.svg',
    }
  ],
  demo: {
    event: 'IOT_digital_core_start'
  }
};

const Iot = (props) => (
  <Layout {...props}>
    <Landing
      data={iot}
      page={'iot'}
    />
  </Layout>
);

export default withIntl(Iot);

